import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contact_details_files_navigation = _resolveComponent("contact-details-files-navigation")!
  const _component_tm_upload_file = _resolveComponent("tm-upload-file")!
  const _component_contact_details_files_table = _resolveComponent("contact-details-files-table")!
  const _component_white_block = _resolveComponent("white-block")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_contact_details_files_navigation, {
      search: _ctx.search,
      "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      filters: _ctx.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
      activities: _ctx.activities,
      placeholder: "Search files",
      "filters-list": _ctx.filtersList
    }, null, 8, ["search", "filters", "activities", "filters-list"]),
    _createVNode(_component_white_block, { class: "pa-6 mt-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_upload_file, { class: "mb-4" }),
        _createVNode(_component_contact_details_files_table, {
          headers: _ctx.contactFilesHeaders,
          items: _ctx.contactFilesFiltered,
          "items-name": "files",
          "item-key": "id",
          search: _ctx.search
        }, null, 8, ["headers", "items", "search"])
      ]),
      _: 1
    })
  ]))
}