
import { defineComponent, ref, computed } from 'vue'
import TmUploadFile from '@/components/shared/TmUploadFile.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import ContactDetailsFilesNavigation from '@/components/pages/contacts/organizationDetails/ContactDetailsFilesNavigation.vue'
import ContactDetailsFilesTable from '@/components/pages/contacts/organizationDetails/ContactDetailsFilesTable.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import { useActivities } from '@/compositions/contacts/useActivities'
import { activitiesFilter } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'

export default defineComponent({
  name: 'ContactDetailsFiles',
  components: {
    TmUploadFile,
    WhiteBlock,
    ContactDetailsFilesTable,
    ContactDetailsFilesNavigation,
  },
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const search = ref('')
    const { filters } = useFilters(activitiesFilter)
    const { filtersList } = useActivities()

    const contactFilesHeaders = ref([
      { text: 'Name', value: 'name' },
      { text: 'Size', value: 'size', width: 100 },
      { text: 'Uploaded by', value: 'uploaded-by', width: '25%' },
      { text: 'Last updated', value: 'lastUpdated', format: (val: string) => formatDate(val), width: 120 },
    ])

    const contactFiles = ref(getTableData('files'))
    const contactFilesFiltered = computed<any>(() => {
      return contactFiles.value.filter((item: any) => (item.name.toLowerCase().includes(search.value.toLowerCase()) || item.uploadedBy.fullName.toLowerCase().includes(search.value.toLowerCase())))
    })

    return {
      search,
      filters,
      filtersList,
      contactFilesHeaders,
      contactFilesFiltered,
      formatDate,
    }
  },
})
