
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmFile from '@/components/shared/TmFile.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import Highlight from '@/components/shared/Highlight.vue'
import ContactDetailsFilesDropdown from '@/components/pages/contacts/organizationDetails/ContactDetailsFilesDropdown.vue'

export default defineComponent({
  components: {
    Highlight,
    TmTable,
    TmPerson,
    TmFile,
    ContactDetailsFilesDropdown,
  },
  props: {
    search: {
      type: String,
    },
  },
})
