import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover pointer" }
const _hoisted_2 = { class: "text-uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_file = _resolveComponent("tm-file")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_contact_details_files_dropdown = _resolveComponent("contact-details-files-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-name": _withCtx((props) => [
      _createElementVNode("a", _hoisted_1, [
        _createVNode(_component_tm_file, {
          file: props.row.fileType.type,
          class: "mr-3"
        }, null, 8, ["file"]),
        _createElementVNode("div", null, [
          _createVNode(_component_highlight, {
            text: `${props.row.name}${props.row.fileType.extension}`,
            search: _ctx.search
          }, null, 8, ["text", "search"])
        ])
      ])
    ]),
    "body-cell-size": _withCtx((props) => [
      (props.row.fileType.size)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(props.row.fileType.size.number) + " ", 1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(props.row.fileType.size.byte), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    "body-cell-uploaded-by": _withCtx((props) => [
      (_openBlock(), _createBlock(_component_router_link, {
        key: props.row.uploadedBy.fullName,
        to: { name: 'base.contacts.contactDetails', params: { id: props.row.id } },
        class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover w100pr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: props.row.uploadedBy.fullName,
            "avatar-url": props.row.uploadedBy.avatar,
            "avatar-color": props.row.uploadedBy.avatarColor,
            search: _ctx.search,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-url", "avatar-color", "search"])
        ]),
        _: 2
      }, 1032, ["to"]))
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_contact_details_files_dropdown)
    ]),
    _: 1
  }))
}